import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { sendFetchApi } from "../api/GET.method";
import Spinner from "../components/Spinner";

const CallList = () => {
  const { callList } = useParams();
  const [callsData, setCallsData] = useState({
    title: "",
    preamble: "",
    callList: [],
  });

  useEffect(() => {
    document.title = "Указы Санату Кумаре";
    (async () => {
      try {
        const result = await sendFetchApi(`call/?list=${callList}`);
        setCallsData({
          ...result,
          callList: result.callList.map((text, index) => ({
            text,
            id: index,
          })),
        });
        document.title = result.title;
      } catch (error) {
        console.log(error);
      }
    })();
  }, [callList]);

  return (
    <Spinner data={callsData?.callList?.length}>
      <div className="callCommon">
        <h6 className="spaceBetween">
          <div>{callsData.title}</div>
          <div className="lightGray">{callsData.counter}</div>
        </h6>
        <h4>{callsData.preamble}</h4>
        <ol className="classList">
          {callsData.callList.map(({ id, text }) => (
            <li key={id} className="callItem">
              {text}
            </li>
          ))}
        </ol>
        <h4>{callsData.sealing}</h4>
      </div>
    </Spinner>
  );
};

export default CallList;

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import CallList from "./components/CallList";
import MainPage from "./components/MainPage";
import Oper from "./components/Oper";
import Retreat from "./components/Retreat";
import Statistic from "./components/Statistic";
import TagsPage from "./components/TagPage/TagsPage";
import { RetreatPage, ClockPage } from "./Pages";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/cclock" element={<ClockPage />} />
        <Route path="/retreat" element={<RetreatPage />} />
        <Route path="/retreat/:master" element={<Retreat />} />
        <Route path="/tags" element={<TagsPage />} />
        <Route path="/stat" element={<Statistic />} />
        <Route path="/oper" element={<Oper />} />
        <Route path="/:callList" element={<CallList />} />
        <Route path="/" element={<MainPage />} />
      </Routes>
    </Router>
  );
}

export default App;

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSync } from '@fortawesome/free-solid-svg-icons/'
// import { faYinYang } from '@fortawesome/free-solid-svg-icons/'

const Spinner = ({ data, children }) => {
  return (
    <>
      {data ? (
        <>{children}</>
      ) : (
        <div className="absolutely-centered">
          {/* <FontAwesomeIcon icon={faYinYang} spin size='6x' /> */}
        </div>
      )}
    </>
  );
};

export default Spinner;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/order */
import { useEffect, useState } from "react";

import { positionMap } from "./Clock.data";
import clock_base_svg from "./pictures/clock_base.svg";
import fullMoonOver from "./pictures/clock_full_moon_over.svg";
import clock_moon from "./pictures/clock_moon.svg";
import clock_stage_svg from "./pictures/clock_stage.svg";
import clock_month from "./pictures/month.svg";
import clock_year from "./pictures/year.svg";

import "./ClockPage.css";
import {
  setYearlyStageFoo,
  setStageFoo,
  getDateParts,
  setMonthHour,
  setMoonStage,
} from "./utils";

const getScaleAndPosition = (angle = 0) => {
  const { x, y } = positionMap[angle];
  return {
    transform: `scale(1.185) translate(${x}px,${y}px) rotate(${angle}deg)`,
  };
};

export const ClockPage = () => {
  const [monthStage, setMonthStage] = useState(0);
  const [today, setToday] = useState(new Date());
  const [dateTitle, setDateTitle] = useState(new Date().toLocaleDateString());
  const [fullMoon, setFullMoon] = useState(false);
  const [yearStage, setYearStage] = useState(0);

  const [stage, setStage] = useState(0);
  const [moon, setMoon] = useState(0);
  const [showStage, setShowStage] = useState(true);

  useEffect(() => {
    const { day, month, year } = getDateParts(today);

    setShowStage(!(month === 5 && day === 26));
    setDateTitle(`${day} . ${month + 1} . ${year}`);
    setMonthStage(setMonthHour(today));
    setStage(setStageFoo(today));
    setYearStage(setYearlyStageFoo(today));
    setMoonStage(today)
      .then((responce) => {
        if (responce) {
          const { fullmoon, zodiac } = responce;
          setFullMoon(fullmoon);
          setMoon(zodiac * 30);
        } else {
          console.log("NO MOON DATA", today);
        }
      })
      .catch((err) => {
        console.log("NO MOON DATA", today);
        console.log(err);
      });
    console.log(
      "window.location.hostname === localhost",
      window.location.hostname === "localhost",
    );
    console.log("window.location.hostname", window.location.hostname);
    const todayInterval = setInterval(() => {
      if (window.location.hostname === "localhost") {
        const { day, month, year } = getDateParts(today);
        const newDay = new Date(year, month, day + 1);
        setToday(newDay);
      }
    }, 2000);
    return () => clearInterval(todayInterval);
  }, [today]);

  return (
    <div className={`mainPic ${fullMoon && "yellow"}`}>
      <img
        style={getScaleAndPosition(yearStage)}
        src={clock_year}
        alt="YEAR STAGE"
      />
      <img
        style={getScaleAndPosition(monthStage)}
        src={clock_month}
        alt="MONTH STAGE"
      />
      <img
        src={clock_base_svg}
        alt="Cosmic Clock"
        style={{
          transform: `scale(1.185) translate(-139px,-8px)`,
        }}
      />
      {showStage && (
        <img
          style={getScaleAndPosition(stage)}
          src={clock_stage_svg}
          alt="STAGE VALUE"
        />
      )}
      <img
        style={getScaleAndPosition(moon)}
        src={clock_moon}
        alt="Cosmic Clock"
      />
      {fullMoon && (
        <img
          src={fullMoonOver}
          alt="Cosmic Clock"
          style={getScaleAndPosition()}
        />
      )}
      <div className="dataTitle" onClick={() => setFullMoon((pre) => !pre)}>
        {dateTitle}
      </div>
    </div>
  );
};

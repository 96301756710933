export const positionMap = {
  0: { x: -139, y: -8 },
  30: { x: -118, y: -77 },
  60: { x: -65, y: -126 },
  90: { x: 5, y: -142 },
  120: { x: 73, y: -121 },
  150: { x: 122, y: -68 },
  180: { x: 138, y: 2 },
  210: { x: 117, y: 71 },
  240: { x: 65, y: 120 },
  270: { x: -5, y: 136 },
  300: { x: -74, y: 115 },
  330: { x: -123, y: 62 },
};

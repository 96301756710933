// import StatList from '../components/StatList'
import { useState, useEffect } from "react";

import { sendFetchApiPOST } from "../api/POST.method";
import Statlist from "../components/StatList";

import Spinner from "./Spinner";

const Statistic = () => {
  const [allStat, setAllStat] = useState();
  const getStat = async () => {
    try {
      setAllStat(
        await sendFetchApiPOST("stat", {
          stat: [1, 7, 30],
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStat();
    const interval = setInterval(() => getStat(), 10000);
    return () => clearInterval(interval);
  }, []);

  useState(() => []);
  return (
    <Spinner data={allStat}>
      <div className="grid">
        {allStat && allStat.map((stat) => <Statlist {...stat} />)}
      </div>
    </Spinner>
  );
};
export default Statistic;

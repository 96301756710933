const dayPeriod = 1000 * 60 * 60 * 24;
const yearlyPeriodMath = dayPeriod * 365;
const veryStartPoint = new Date(1972, 5, 27);
const transitionDay = 22;
const twoWeeekPeriod = dayPeriod * 14;

export {
  dayPeriod,
  yearlyPeriodMath,
  veryStartPoint,
  transitionDay,
  twoWeeekPeriod,
};

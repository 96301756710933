import { useState, useEffect, useRef } from "react";

import { sendFetchApi } from "../api/GET.method";

import Spinner from "./Spinner";

const callBody = ({ title, listNumber, tos }) => `🔔🔔🔔
 
**${title}**
  
https://amasters.info/${listNumber}
  
${tos}`;

const Oper = () => {
  const inputEl = useRef("");
  const [list, setList] = useState();
  const [listStyle, setListStyle] = useState(new Array(50).fill(255));

  const getOptList = async () => {
    try {
      const list = (await sendFetchApi("oper")) || [];
      setList(list);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getOptList();
    const interval = setInterval(() => {
      getOptList();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const clickHandler = (item) => {
    const text = callBody(item);
    setListStyle((pre) => {
      const newArr = [...pre];
      newArr[item.listNumber] -= 30;
      return newArr;
    });

    inputEl.current.value = text;
    inputEl.current.select();
    document.execCommand("copy");
    console.log(text);
  };

  const rowStyle = ({ listNumber }) => {
    const color = listStyle[listNumber];
    return { backgroundColor: `rgb(${color}, ${color}, ${color})` };
  };

  return (
    <Spinner data={list}>
      <div className="operCommon">
        <table className="operTable">
          <tbody>
            {list &&
              list.map((item) => (
                <tr
                  className="operRow"
                  style={rowStyle(item)}
                  key={item.listNumber}
                  onClick={() => clickHandler(item)}
                >
                  <td className="operCell">{item.listNumber}</td>
                  <td className="operCell">{item.title}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <textarea
          className="operTextArea"
          ref={inputEl}
          cols="50"
          rows="10"
        ></textarea>
      </div>
    </Spinner>
  );
};

export default Oper;

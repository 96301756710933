import { adressString } from "./getAdress";

export const sendFetchApiPOST = async (queryString, body) => {
  try {
    const request = await fetch(adressString(queryString), {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return await request.json();
  } catch (error) {
    console.log(error);
  }
};

import { useState, useEffect } from "react";

import { sendFetchApi } from "../../api";
import Spinner from "../../components/Spinner";

export const RetreatPage = () => {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await sendFetchApi(`retreat`);
        console.log(response);
        setResponse(response);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  return (
    <Spinner data={response}>
      <h1>ALL RETREATS</h1>
      <pre>{JSON.stringify(response, null, 3)}</pre>
    </Spinner>
  );
};

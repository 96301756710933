import { yearlyPeriodMath, veryStartPoint } from "./Constants";

const setYearlyStageFoo = (today) => {
  let yearlyStage = Math.trunc(
    ((+today - veryStartPoint) / yearlyPeriodMath) % 14,
  );

  if (yearlyStage === 12) {
    yearlyStage = 0;
  } else if (yearlyStage === 13) {
    yearlyStage = 6;
  }
  return yearlyStage * 30;
};

export { setYearlyStageFoo };
